import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons/faMicrosoft'
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey'
import Vue  from 'vue'

window.addEventListener('load', () => {
    const elems = []
    const list = document.getElementsByClassName('fa')
    for (let i = 0; i < list.length; ++i) {
        elems.push(list.item(i))
    }

    for (const el of elems) {
        if (el.classList.contains('fa-microsoft')) {
            new Vue({
                el,
                render: h => h(FontAwesomeIcon, { props: { icon: faMicrosoft } })
            })
        }
        if (el.classList.contains('fa-key')) {
            new Vue({
                el,
                render: h => h(FontAwesomeIcon, { props: { icon: faKey } })
            })
        }
    }

    const loginSelectForm = document.getElementById('login-select-form')
    const usernamePasswordForm = document.getElementById('username-password-login-form')
    const usernamePasswordButton = document.getElementById('username-password-login-button')
    const usernamePasswordCancelButton = document.getElementById('username-password-login-cancel-button')
    const usernamePasswordErrorMessage = document.getElementById('username-password-error-message')
    const usernameInput = document.getElementById('username')

    usernamePasswordButton.addEventListener('click', () => {
        loginSelectForm.classList.add('is-hidden')
        usernamePasswordForm.classList.remove('is-hidden')
        window.setTimeout(() => { usernameInput.focus() }, 0)
    })

    usernamePasswordCancelButton.addEventListener('click', () => {
        loginSelectForm.classList.remove('is-hidden')
        usernamePasswordForm.classList.add('is-hidden')
        usernamePasswordErrorMessage.classList.add('is-hidden')
    })
})